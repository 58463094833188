import React, { useEffect } from "react";

import { Link, Routes, Route, Navigate } from "react-router-dom";

import "./App.css";
import GestaltorLogo from "./images/Gestaltor_Logo.svg";

import Homepage from "./Homepage/Homepage";
import ThankYou from "./Homepage/ThankYou";
import Release from "./Release/Release";

// component with all the available routes and the redirect option
function App() {
  useEffect(() => {
    const script = document.createElement("script");

    script.src = "https://plausible.io/js/script.file-downloads.js";
    script.async = true;
    script.defer = true;
    script.setAttribute("file-types", "dmg,AppImage,exe");
    script.dataDomain = "gestaltor.download";

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  <script defer></script>;

  return (
    <>
      <div className="content">
        <div className="header">
          <Link to="/" className="headerMainPage headerItem">
            <img src={GestaltorLogo} alt="GestaltorLogo" className="logoImg" />
            <span>Gestaltor Download</span>
          </Link>
          <div>
            <a className="headerItem" href="/ReleaseNotes">
              Release notes
            </a>
            <a className="headerItem" href="https://gestaltor.help/">
              Documentation
            </a>
            <a
              className="headerItem"
              href="https://gestaltor.io/#downloadsScrollTarget"
            >
              Subscribe
            </a>
          </div>
        </div>
        <Routes>
          <Route path="*" element={<Navigate to="/" replace={true} />} />
          <Route exact path="/ReleaseNotes" element={<Release />} />
          <Route exact path="/ThankYouForDownload" element={<ThankYou />} />
          <Route exact path="/:version" element={<Homepage />} />
          <Route exact path="/" element={<Homepage />} />
        </Routes>
      </div>
      <footer className="footer">
        <a href="https://ux3d.io" target="blank">
          &#169; 2023 UX3D GmbH
        </a>
        <a href="https://ux3d.io/imprint" target="blank">
          Imprint
        </a>
      </footer>
    </>
  );
}

export default App;
