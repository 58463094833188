import React, { useEffect, useState, useCallback } from "react";

import { useNavigate, useParams } from "react-router-dom";

import osDetect from "platform-detect/os.mjs"; // https://www.npmjs.com/package/platform-detect

import "./Homepage.css";

import { firebaseInstance } from "../firebase.js";

import apple from "../images/MacIcon.svg";
import flag from "../images/WindowsIcon.svg";
import penguin from "../images/LinuxIcon.svg";
import workInProgress from "../images/WorkInProgress.svg";

import {
  Checkbox,
  FormControlLabel,
  Button,
  Select,
  MenuItem,
} from "@mui/material";

const iconMapping = {
  windows: flag,
  macOS: apple,
  linux: penguin,
};

const nightlyName = "Gestaltor 2.0 (Pre-alpha)";

// component containing the download box with selection options for version and operating system, check for accept term and conditions, and the download button
export default function DownloadBox() {
  const routeParams = useParams();

  const [availableProgramVersions, setAvailProgVersions] = useState([
    { name: "Gestaltor", url: "", version: "version loading" },
  ]);

  const [gestaltorVersion, setGestaltorVersion] = useState("");
  const [os, setOs] = useState("windows");

  // term and conditions checkbox check status
  const [agreedCheck, setAgreedCheck] = useState(false);

  // redirect: used to redirect the user to the thank you for downloading page
  const navigate = useNavigate();

  const fillVersionMenu = useCallback(
    (firebaseRefArray) => {
      let versionsArr = [];

      firebaseRefArray.forEach((folderRef) => {
        let pathArray = folderRef._location.path_.split("/");
        versionsArr.push({
          name: "Gestaltor",
          url: folderRef._location.path_,
          version: pathArray[pathArray.length - 1],
        });
      });
      // sort versions (newest at front)
      versionsArr.sort((a, b) => b.version.localeCompare(a.version));
      // put nightly at end
      const nightlyIDX = versionsArr.findIndex(
        (elem) => elem.version === "Nightly"
      );
      if (nightlyIDX > -1) {
        versionsArr.push(versionsArr.splice(nightlyIDX, 1)[0]);
      }

      setAvailProgVersions(versionsArr);

      // set selected version to url parameter or to first array entry
      if (versionsArr.find((e) => e.version === routeParams.version)) {
        setGestaltorVersion(routeParams.version);
      } else {
        setGestaltorVersion(versionsArr[0].version);
      }
    },
    [routeParams.version]
  );

  // This useEffect runs only once at startup.
  // It detects and preselects the correct operating system.
  // It als loads the index JSON
  useEffect(() => {
    // checking if the operating system has an available download file
    if (osDetect.macos || osDetect.linux || osDetect.windows) {
      if (osDetect.macos) {
        setOs("macOS");
      } else if (osDetect.linux) {
        setOs("linux");
      } else if (osDetect.windows) {
        setOs("windows");
      } else {
        // default selected system
        setOs("windows");
      }
    } else {
      // default selected system
      setOs("windows");
    }

    firebaseInstance.executeCallbackOnListOfSubFolders(
      "/gestaltor/public",
      fillVersionMenu
    );
  }, [fillVersionMenu]);

  // download the released based on the version and the system
  const downloadSelectedVersion = useCallback(() => {
    if (agreedCheck === false) {
      return;
    }
    let downloadPath = "gestaltor/public/" + gestaltorVersion + "/" + os + "/";

    firebaseInstance.downloadFile(downloadPath);

    // redirecting the user to the thank you for downloading page
    navigate("/ThankYouForDownload");
  }, [navigate, os, gestaltorVersion, agreedCheck]);

  return (
    <form className="downloadBox">
      <div className="width100">
        <Select
          id="versionSelection"
          value={gestaltorVersion}
          className="width100 marginBottomDropDown selectComp"
          onChange={function (event) {
            setGestaltorVersion(event.target.value);
          }}
        >
          {availableProgramVersions.map((data, index) => {
            return (
              <MenuItem
                value={data.version}
                key={data.version}
                divider={
                  index <= availableProgramVersions.length - 2 &&
                  availableProgramVersions[index + 1].version === "Nightly"
                }
              >
                {data.version === "Nightly" && (
                  <img src={workInProgress} className="selectCompIcon" alt="" />
                )}
                {data.version === "Nightly" && nightlyName}
                {data.version !== "Nightly" && data.name + " " + data.version}
              </MenuItem>
            );
          })}
        </Select>
        <Select
          id="osSelect"
          value={os}
          className="width100 selectComp"
          onChange={function (event) {
            setOs(event.target.value);
          }}
        >
          <MenuItem value="windows">
            <img
              src={iconMapping["windows"]}
              className="selectCompIcon"
              alt=""
            />
            Windows
          </MenuItem>
          <MenuItem value="macOS">
            <img src={iconMapping["macOS"]} className="selectCompIcon" alt="" />
            Mac
          </MenuItem>
          <MenuItem value="linux">
            <img src={iconMapping["linux"]} className="selectCompIcon" alt="" />
            Linux
          </MenuItem>
        </Select>
      </div>

      <p className="versionInfoText">
        {gestaltorVersion === "Nightly" &&
          nightlyName +
            " is an experimental version that should not be used in your production pipeline."}
      </p>

      <div>
        <FormControlLabel
          className="width100 marginBottom10"
          control={
            <Checkbox
              color="info"
              checked={agreedCheck}
              disableRipple={true}
              sx={{
                color: "#26C6DA",
                "&.Mui-checked": {
                  color: "#26C6DA",
                },
              }}
              onChange={() => setAgreedCheck(!agreedCheck)}
            />
          }
          label={
            <p>
              I agree to the{" "}
              <a
                href="https://gestaltor.io/wp-content/uploads/2020/06/EULA.pdf"
                target="blank"
                className="link"
              >
                terms and conditions
              </a>
              .
            </p>
          }
        />
        <Button
          variant="contained"
          className="downloadBtn"
          id={agreedCheck ? "enabledBtn" : "disabledBtn"}
          onClick={downloadSelectedVersion}
        >
          Download
        </Button>
      </div>
    </form>
  );
}
