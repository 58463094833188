import React, { useState, useEffect } from "react";
import ReactMarkdown from "react-markdown";

// import { useParams } from "react-router-dom";

import { firebaseInstance } from "../firebase.js";
import "./Release.css";

//import GestaltorDesktop from "../images/GestaltorDesktop.png";

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Container,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const nightlyName = "Gestaltor 2.0 (Pre-alpha)";

// component for release notes dedicated page contaning and header and a navigation tabs list for all the different notes
// if too many notes, it has the feature to scroll horizontally thought all the tabs
export default function Release() {
  // storing the url params (the id has the same value of the release note index )
  // const { id } = useParams();

  // [{chnageLog: "asdas", version:"asdasdasd"}, ...]
  const [changeLog, setChangeLog] = useState([]);

  // release note selected (default it's the first one of releasesList)
  const [expandedAccordion, setExpandedAccordion] = useState(0);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpandedAccordion(newExpanded ? panel : false);
  };

  // This useEffect runs only once at startup.
  // It als loads the index JSON
  useEffect(() => {
    const currentChangeLogPromise = firebaseInstance.gatherChangeLogs();
    const legacyChangeLogPromise = firebaseInstance.gatherAllLegacyChangeLogs();

    Promise.all([currentChangeLogPromise, legacyChangeLogPromise]).then(
      (dataArray) => {
        dataArray = dataArray[0].concat(dataArray[1]);
        dataArray = dataArray.filter(
          (word) => word.changeLog !== "Change log could not be loaded!"
        );
        // sort versions (newest at front)
        dataArray.sort((a, b) => b.version.localeCompare(a.version));
        // put nightly at end
        const nightlyIDX = dataArray.findIndex(
          (elem) => elem.version === "Nightly"
        );
        if (nightlyIDX > -1) {
          dataArray.push(dataArray.splice(nightlyIDX, 1)[0]);
        }
        setChangeLog(dataArray);
      }
    );
  }, []);

  return (
    <div className="releaseContent">
      <div className="releaseHeader">
        <h2>Release notes</h2>
        <p>All the details about Gestaltor updates</p>
      </div>
      {/* create tabs */}
      <Container maxWidth="lg">
        {/* create tab content */}
        <div className="notesList">
          {changeLog.map((data, index) => {
            return (
              <Accordion
                key={index}
                expanded={index === expandedAccordion}
                onChange={handleChange(index)}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  {data.version === "Nightly" && nightlyName}
                  {data.version !== "Nightly" && "Gestaltor " + data.version}
                </AccordionSummary>
                <AccordionDetails>
                  <ReactMarkdown children={data.changeLog} />
                </AccordionDetails>
              </Accordion>
            );
          })}
        </div>
      </Container>
    </div>
  );
}
