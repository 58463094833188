import { initializeApp } from "firebase/app";
import {
  getStorage,
  ref,
  getBlob,
  getDownloadURL,
  listAll,
} from "firebase/storage";
import {
  getAuth,
  GoogleAuthProvider,
  EmailAuthProvider,
  setPersistence,
  browserLocalPersistence,
} from "firebase/auth";

class Firebase {
  jsonIndex = "";
  constructor() {
    // TODO: Replace the following with your app's Firebase project configuration
    // See: https://firebase.google.com/docs/web/learn-more#config-object
    const firebaseConfig = {
      apiKey: "AIzaSyDTBT4Axi3qonlYvJDhX8iOVsJVnXF39y8",
      authDomain: "gestaltor-download.firebaseapp.com",
      projectId: "gestaltor-download",
      storageBucket: "gestaltor-download.appspot.com",
      messagingSenderId: "264662847773",
      appId: "1:264662847773:web:e1e08b32928c72ef5e91f4",
      measurementId: "G-QWRJNQL1Y6",
    };

    // Initialize Firebase
    this.app = initializeApp(firebaseConfig);
    this.storage = getStorage(this.app);

    // Initialize the FirebaseUI Widget using Firebase.
    this.auth = getAuth(this.app);
    setPersistence(this.auth, browserLocalPersistence);
    this.signInOptions = [
      // List of OAuth providers supported.
      GoogleAuthProvider.PROVIDER_ID,
      {
        provider: EmailAuthProvider.PROVIDER_ID,
        requireDisplayName: false,
      },
    ];

    this.previousUpdateModulePromise = null;
  }

  /**
   *
   * @param {*} downloadPath jsonPath within that file (i.e gestaltor/public/release/windows/x64).
   *                         downloadPath must not end on a slash or backslash.
   */
  async downloadFile(downloadPath) {
    // use try catch here to catch exceptions.
    // for example wrong download paths
    try {
      const folderRef = ref(this.storage, downloadPath);

      // Find all the prefixes and items.
      await listAll(folderRef).then((res) => {
        if (res.items.length === 0) {
          alert("Your file could not be downloaded.");
          console.log("There is no file in the specified folder.");
          return;
        }
        // get download url from firebase
        getDownloadURL(res.items[0])
          .then((url) => {
            this.downloadURL(url);
          })
          .catch((error) => {
            console.log(error);
          });
      });
    } catch (e) {
      console.log(e);
    }
  }

  async getAllSubFolders(url) {
    let reference = ref(this.storage, url);

    let folders = [];

    // Find all the prefixes and items.
    await listAll(reference)
      .then((res) => {
        res.prefixes.forEach((folderRef) => {
          folders.push(folderRef._delegate._location.path_.split("/")[1]);
        });
      })
      .catch((error) => {});
    return folders;
  }

  async getFileAsText(firebaseUrl, errorText, reference) {
    // Create a reference under which you want to listen
    let fileRef = ref(this.storage, firebaseUrl);

    if (firebaseUrl !== undefined) {
      fileRef = ref(this.storage, firebaseUrl);
    } else if (reference !== undefined) {
      fileRef = reference;
    } else {
      return errorText;
    }

    try {
      const blob = await getBlob(fileRef);
      return blob.text();
    } catch (e) {
      console.error(e);
      return errorText;
    }
  }

  async getChangeLog(url) {
    let arr = url.split("/");
    let versionNumber = arr[arr.length - 2];
    let changeLog = await this.getFileAsText(
      url,
      "Change log could not be loaded!"
    );
    return { changeLog: changeLog, version: versionNumber };
  }

  async getLegacyChangeLog(ref) {
    const filePath = ref._location.path_;
    const filePathSplitArr = filePath.split("/");
    const fileName = filePathSplitArr[filePathSplitArr.length - 1];
    const versionNumber = fileName.replace(/\.[^/.]+$/, "");
    let changeLog = await this.getFileAsText(
      undefined,
      "Change log could not be loaded!",
      ref
    );
    return { changeLog: changeLog, version: versionNumber };
  }

  /**
   * This function performs an actual download from a provided url.
   * @param {*} url
   */
  downloadURL(url) {
    const tmp = document.createElement("a");
    tmp.href = url;
    document.body.appendChild(tmp);
    tmp.click();
    document.body.removeChild(tmp);
  }

  /**
   * Calls the callback function on all folders in the url.
   * Callback has to take an array of firebase references as first parameter.
   * @param {*} url
   * @param {*} callback interface: callback(firebaseReferenceArray)
   */
  async executeCallbackOnListOfSubFolders(url, callback) {
    let reference = ref(this.storage, url);

    // Find all the prefixes and items.
    await listAll(reference)
      .then((res) => {
        callback(res.prefixes);
      })
      .catch((error) => {
        // Uh-oh, an error occurred!
      });
  }

  /**
   * @returns promise containing array with promises for each changelog
   */
  async gatherChangeLogs() {
    let reference = ref(this.storage, "/gestaltor/public");

    let changeLogPromises = [];
    // Find all the prefixes and items.
    await listAll(reference)
      .then((res) => {
        res.prefixes.forEach((itemRef) => {
          let fileURL = itemRef._location.path_ + "/change_log.md";

          changeLogPromises.push(firebaseInstance.getChangeLog(fileURL));
        });
      })
      .catch((error) => {});

    return Promise.all(changeLogPromises);
  }

  /**
   * @returns promise containing array with promises for each changelog
   */
  async gatherAllLegacyChangeLogs() {
    let reference = ref(this.storage, "/gestaltor/legacyChangeLogs");

    let changeLogPromises = [];
    // Find all the prefixes and items.
    await listAll(reference)
      .then((res) => {
        res.items.forEach((itemRef) => {
          changeLogPromises.push(firebaseInstance.getLegacyChangeLog(itemRef));
        });
      })
      .catch((error) => {});

    return Promise.all(changeLogPromises);
  }
}

let firebaseInstance = new Firebase();

export { firebaseInstance, Firebase };
