import React from "react";

import "./Homepage.css";

import GestaltorLaptop from "../images/GestaltorLaptop.png";
import GestaltorDesktop from "../images/GestaltorDesktop.png";

// component containing the thank you message after the download has started
export default function ThankYou() {
  return (
    <div className="content">
      <div className="gradientBack"></div>
      <div className="contentBoxDownload">
        <div className="downloadBox">
          <div className="thankYouBox">
            <h4 className="thankYouHeader">
              Thank you for downloading Gestaltor…
            </h4>
            <div>
              <p className="helpP">Download not working?</p>
              <a href="mailto:support@ux3d.io" className="helpLink">
                support@ux3d.io
              </a>
            </div>
            <div>
              <p className="helpP">Upgrade to Professional</p>
              <a
                href="https://gestaltor.io/#downloadsScrollTarget"
                target="blank"
                className="helpLink"
              >
                Subscribe to Gestaltor
              </a>
            </div>
          </div>
        </div>
        <div className="imgBox">
          <img
            src={GestaltorLaptop}
            alt="GestaltorLaptop"
            className="firstImg"
          />
          <img
            src={GestaltorDesktop}
            alt="GestaltorDesktop"
            className="secondImg"
          />
        </div>
      </div>
    </div>
  );
}
