import React from "react";

import "./Homepage.css";

import GestaltorLaptop from "../images/GestaltorLaptop.png";
import GestaltorDesktop from "../images/GestaltorDesktop.png";

import DownloadBox from "./DownloadBox";

// component for the homepage: header, footr and the download page with options for versions and operating system
export default function Homepage() {
  return (
    <div className="content">
      <div className="gradientBack"></div>
      <div className="contentBoxDownload">
        <DownloadBox />
        <div className="imgBox">
          <img
            src={GestaltorLaptop}
            alt="GestaltorLaptop"
            className="firstImg"
          />
          <img
            src={GestaltorDesktop}
            alt="GestaltorDesktop"
            className="secondImg"
          />
        </div>
      </div>
    </div>
  );
}
